<template lang="pug">
.footer.d-flex
  .d-flex.help-section(v-if="helpLink")
    UilQuestionCircle(size="1.5rem" color="#505763")
    a.ml-2.brand-link.color-brand-secondary(:href="helpLink" target="_blank") {{ $t('help') }}
  .d-flex.button-holder
    //- left button states
    template(v-if="isFirstStep")
      button.brand-btn.brand-btn-link.mr-3.font-weight-semibold(
        @click="$emit('click', 'cancel')"
        style="color: #505763"
      ) {{ $t('cancel') }}
    template(v-else)
      button.brand-btn.brand-btn-link.mr-3.font-weight-semibold(
        @click="$emit('click', 'back')"
        style="color: #505763"
      ) {{ $t('back') }}

    //- right button states
    template(v-if="isLastStep")
      button.brand-btn(
        @click="$emit('click', 'done')"
        :class="isButtonActive ? 'brand-btn-primary' : 'brand-btn-secondary'"
      ) {{ $t('done') }}
    template(v-else)
      button.brand-btn(
        @click="$emit('click', 'next')"
        :class="isButtonActive ? 'brand-btn-primary' : 'brand-btn-secondary'"
      ) {{ $t('next') }}
</template>
<script>
  import { UilQuestionCircle } from '@iconscout/vue-unicons';

  export default {
    components: {
      UilQuestionCircle,
    },

    props: {
      activeStep: {
        type: String,
      },
      steps: {
        type: Array,
        default: () => ['setup', 'listId', 'fieldMapping'],
      },

      helpLink: {
        type: String,
        default: null,
      },

      isButtonActive: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isFirstStep() {
        return this.steps[0] === this.activeStep;
      },
      isLastStep() {
        return this.steps[this.steps.length - 1] === this.activeStep;
      },
    },

    methods: {
      openHelp() {
        const helpUrl = this.helper[this.integrationType].helpUrl;
        window.open(helpUrl, '_blank');
      },
    },
  };
</script>
