import { KlaviyoSettings } from '@/services/integrations/settings/KlaviyoSettings';
import { ShopifyCustomer } from '@/services/integrations/settings/ShopifyCustomer';
import { Webhook } from '@/services/integrations/settings/Webhook';
import { MailChimp } from '@/services/integrations/settings/MailChimp';
import { SendinblueSettings } from '@/services/integrations/settings/SendinblueSettings';
import { ActiveCampaign } from '@/services/integrations/settings/ActiveCampaign';
import { HubSpotV2 } from '@/services/integrations/settings/HubSpotV2';
import { HighLevel } from '@/services/integrations/settings/HighLevel';
import { Dotmailer } from '@/services/integrations/settings/Dotmailer';
import { Recart } from '@/services/integrations/settings/Recart';
import { Selzy } from '@/services/integrations/settings/Selzy';
import { Unas } from '@/services/integrations/settings/Unas';

class IntegrationSettingsFactory {
  static create(type) {
    switch (type) {
      case 'klaviyo':
        return new KlaviyoSettings();
      case 'shopifyCustomer':
        return new ShopifyCustomer();
      case 'webhook':
        return new Webhook();
      case 'mailChimp':
        return new MailChimp();
      case 'sendinblue':
        return new SendinblueSettings();
      case 'activeCampaign':
        return new ActiveCampaign();
      case 'hubSpotV2':
        return new HubSpotV2();
      case 'highLevel':
        return new HighLevel();
      case 'dotmailer':
        return new Dotmailer();
      case 'recart':
        return new Recart();
      case 'selzy':
        return new Selzy();
      case 'unas':
        return new Unas();
      default:
        throw Error(`No integration setting with type: ${type}`);
    }
  }
}

export { IntegrationSettingsFactory };
